"use client";

import { Discount } from "@/app/constants/discounts";
import Translations from "@/app/constants/translations";
import { Trans, useTranslation } from "react-i18next";

export default function DiscountAnnouncement(props: Discount) {
    const { t } = useTranslation(Translations.HEADER);

    return (
        <span>
            <Trans
                i18nKey={`${Translations.HEADER}:announcement.discount`}
                values={{
                    name: t(props.name),
                    discount: props.discount,
                    endDate: props.endDate,
                }}
                components={{
                    bold: <span className="font-bold" />,
                }}
            />
        </span>
    );
}
