import moment from "moment/moment";
import { createInstance, i18n, Resource } from "i18next";
import { initReactI18next } from "react-i18next/initReactI18next";
import resourcesToBackend from "i18next-resources-to-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import i18nConfig from "@/i18nConfig";

function getMomentLocale(language: string): string {
    // Get all moment locales imports.
    const momentLocales = (require as any)
        .context("moment/locale", true, /\.js$/);
    const keys: string[] = momentLocales.keys();
    // Get all the keys that match the language.
    const languageKeys = keys
        .map((key) => key.replace("./", "").replace(".js", ""))
        .filter((key) => key.startsWith(language));
    return languageKeys.find((key) => !key.includes("-")) 
        || languageKeys[0] || getMomentLocale("en");
}

export default async function initTranslations(
    locale: string,
    namespaces: string[],
    i18nInstance?: i18n,
    resources?: Resource
) {
    i18nInstance = i18nInstance || createInstance();
    i18nInstance
        .use(LanguageDetector)
        .use(initReactI18next)
        .init({
            resources,
            fallbackLng: "en",
            detection: {
                order: ["querystring", "localStorage", "navigator"],
                lookupLocalStorage: "i18nextLng",
                caches: ["localStorage"],
            },
            interpolation: {
                formatSeparator: ",",
                escapeValue: false,
                format: function(value, formatting, language) {
                    if (!(value instanceof Date)) return value.toString();
                    const locale: string = language || i18nInstance.language;
                    const longLocale: string = getMomentLocale(locale);
                    import(`moment/locale/${longLocale}`);
                    return moment(value).locale(longLocale).format(formatting);
                }
            }
        });

    if (!resources) {
        i18nInstance.use(
            resourcesToBackend(
                (language: Resource, namespace: string) =>
                    import(
                        `/public/locales/${language}/${namespace}.json`
                    )
            )
        );
    }

    await i18nInstance.init({
        lng: locale,
        resources,
        fallbackLng: i18nConfig.defaultLocale,
        supportedLngs: i18nConfig.locales,
        defaultNS: namespaces[0],
        fallbackNS: namespaces[0],
        ns: namespaces,
        preload: resources ? [] : i18nConfig.locales,
    });

    return {
        i18n: i18nInstance,
        resources: i18nInstance.services.resourceStore.data,
        t: i18nInstance.t,
    };
}
